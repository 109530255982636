<template>
  <div class="com-item">
    <div class="w">
      
      <div class="t2">
        <!-- 智能合约 -->
        {{ $t("study.contT2") }}
      </div>
      <div class="p pfont">
        <!-- 智能合约是PSC应用程序的基本构建块。它们是存储在区块链上的计算机程序，允许我们将传统合约转换为数字平行。智能合约非常合乎逻辑——遵循
        if this then that 结构。这意味着它们的行为与编程完全一致，并且无法更改。 -->

        {{ $t("study.contP") }}
      </div>
      <div class="butBox">
        <a href="javascript:;">
          <div class="but1" @click="uti.goPath(store.link.study.contBut)">
            <!-- 部署智能合约 -->
            {{ $t("study.contBut") }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  height: 100vh;
  box-shadow: 0px 2px 8px 0px rgba(212, 217, 250, 0.5);
  background: linear-gradient(0deg, #dae6f2, #edf4fc);
  background-image: url("../../assets/images/v4/study/contBG.png");
  background-position: 90% bottom;
  background-repeat: no-repeat;
  margin-bottom: 250px;
  .w {
    // background-color: pink;
    height: 100vh;
    padding-top: 150px;
    box-sizing: border-box;
    .t1 {
      height: 29px;
      font-size: 30px;
      font-weight: 400;
      color: #333333;
    }
    .t2 {
      margin-top: 65px;
      font-size: 70px;
      font-weight: bold;
      color: #333333;
    }
    .p {

      width: 783px;
      font-size: 24px;
      line-height: 35px;
      font-weight: 500;
      color: #666666;
    }
    .butBox {
      display: flex;
      margin-top: 41px;
      text-align: center;
      .but1 {
        display: inline-block;
        padding: 0 42px;
        height: 54px;
        line-height: 54px;
        background: linear-gradient(-90deg, #10c1fc, #3f65f9);
        box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
        border-radius: 27px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        margin-right: 50px;
      }
    }
  }
}
</style>
